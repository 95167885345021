#logo{

/* Untitled design 1 */

width: 110px;
margin-bottom: 5px;


}

nav li{

    font-weight: 400;
    color: white;
    font-size: 16px;

}


#search{

    width: 100%;

}
 