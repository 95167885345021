#title{

/* Agence detective privée */

font-family: 'Tinos';
font-style: normal;
font-weight: 700;
font-size: 50px;

color: #000000;
text-align: center;
margin-top: 3%;

}


#IntrodictionText{

    padding-top: 150px;

}

#investigation{

  height: 520px;

}




#subtitle1{

/* Agence detective privée */



font-family: 'Tinos';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 34px;

color: #000000;
text-align: center;

}


#subtitle2{

    /* Agence detective privée */
    
    
    
    font-family: 'Tinos';
    font-style: normal;
    font-size: 18px;
    line-height: 34px;
    
    color: #000000;
    text-align: center;
    margin-bottom: 3%;

    }

hr{
    width:50%;
    text-align: center;
    margin: auto;
    color: #000000;
    font-weight: bold;
    border-top: 2px solid #000;

}

 @media only screen and (max-width: 850px) {
    hr {
      font-size: 18px;
      width: 90%;

    }

    #title{

      font-size: 40px;

    }

    #subtitle1{

      font-size: 15px;
   


    }

    #subtitle2{

      font-size: 15px;

    }
  } 

.fadeIn {
    opacity: 1;
    transition: opacity 1s ease-in-out;
  }
  
  .fadeOut {
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }