#rappelez_badges{
    width: 100px;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 40px;
    border-radius: 50%;
    padding: 25px;
    background-color: #D3D3D3;
    transition: transform 0.3s ease-out;
}


#rappelez_badges:hover{

    transform: scale(1.1);
   
}